<template>
  <v-theme-provider>
    <base-section id="news" space="40">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <base-section-heading title="Artikel dan Informasi Terkini" />
            <base-body space="0">
              <v-container fluid>
                <v-tabs v-model="selectedTab" right class="mb-10">
                  <v-tab v-for="category in categories" :key="category">
                    {{ category }}
                  </v-tab>
                </v-tabs>
                <v-row dense>
                  <v-col
                    v-for="data in filteredPosts"
                    :key="data.web_news_id"
                    md="4"
                    xs="12"
                    cols="12"
                  >
                    <v-card
                      class="news-card"
                      v-if="data.web_news_type === 'photo'"
                      @click="Detail(data.web_news_id)"
                    >
                      <v-responsive aspect-ratio="16/9">
                        <v-img
                          :src="getMediaUrl(data.web_news_src_url)"
                          class="responsive-media mb-2"
                          alt="news photo"
                          style="height: 60vh"
                          :contain="data.web_news_contain"
                        >
                          <v-app-bar flat color="rgba(0, 0, 0, 0)">
                            <v-chip
                              color="#ec0000"
                              text-color="white"
                              small
                              label
                            >
                              {{ data.web_news_category }}
                            </v-chip>
                          </v-app-bar>
                        </v-img>
                      </v-responsive>
                      <v-card-actions>
                        {{ data.web_news_name }}
                      </v-card-actions>
                    </v-card>
                    <v-card class="news-card" v-else>
                      <v-responsive aspect-ratio="16/9">
                        <video
                          v-if="data.web_news_type === 'video'"
                          :src="getMediaUrl(data.web_news_src_url)"
                          class="responsive-media"
                          controls
                          style="height: 60vh"
                          :poster="getMediaUrl(data.web_news_src_thumbnail)"
                        ></video>
                      </v-responsive>
                      <v-card-actions>
                        {{ data.web_news_name }}
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: "SectionNews",
  data: () => ({
    selectedTab: 0,
    posts: [],
    categories: ["Semua", "Press Release", "Video"],
  }),
  created() {
    this.List();
  },
  computed: {
    filteredPosts() {
      if (this.categories[this.selectedTab] === "Semua") {
        return this.posts;
      } else {
        return this.posts.filter(
          (data) => this.categories[this.selectedTab] === data.web_news_category
        );
      }
    },
  },
  methods: {
    List() {
      this.posts = [
        {
          web_news_id: "press-release-orion-penjaminan-indonesia",
          web_news_name: "PT Orion Penjaminan Indonesia",
          web_news_src_url: "press-release-min.png",
          web_news_type: "photo",
          web_news_category: "Press Release",
          web_news_contain: false
        },
        {
          web_news_id: "1",
          web_news_name: "PT ORION PENJAMINAN",
          web_news_src_url: "news1.mp4",
          web_news_type: "video",
          web_news_category: "Video",
          web_news_src_thumbnail: "thumbnail-video.png"
        },
        {
          web_news_id: "2",
          web_news_name: "AMAN BERSAMA PENJAMINAN",
          web_news_src_url: "opi-asippindo.mp4",
          web_news_type: "video",
          web_news_category: "Video",
          web_news_src_thumbnail: "thumbnail-video-2.png"
        },
        {
          web_news_id: "OJK-Luncurkan-Peta-Jalan-Pengembangan-Dan-Penguatan-Dana-Pensiun-Indonesia-2024-2028",
          web_news_name: "Peta Jalan Industri Penjaminan 2024-2028",
          web_news_src_url: "penjaminan-aman-ceo-orion.jpg",
          web_news_type: "photo",
          web_news_category: "Artikel",
          web_news_contain: false
        },
      ];
    },
    getMediaUrl(src) {
      return require(`@/assets/news/${src}`);
    },
    MaxLength(content, max) {
      let returns = content;
      if (content.length >= max) {
        returns = content.substr(0, max) + "... ";
      }
      return returns;
    },
    Detail(url) {
      this.$router.push({ name: "artikel-detail", params: { url } });
    },
    FormatDateFullOnlyDate(date) {
      return this.$functions.FormatDateFullOnlyDate(date);
    },
  },
};
</script>

<style scoped>
.news-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.news-card:hover .news-image {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.news-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.responsive-media {
  width: 100%;
  height: 260px;
}
.responsive-media:hover {
  transition: 0.3s background ease-out;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.v-tab--active {
  background: #114e87;
  color: #ffff;
  font-weight: bold;
}

@media (max-width: 600px) {
  .responsive-media {
    height: 200px;
  }
}
</style>
